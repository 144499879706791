<template>
  <a-modal
    :visible="true"
    :title="modalTitle"
    :maskClosable="false"
    @ok="submit"
    @cancel="cancel"
    :confirm-loading="confirmLoading"
    :closable="false"
  >
    <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :rules="rules">
      <a-form-model-item :label="categoryLabel" prop="classifyName">
        <a-input v-model.trim="form.classifyName" :placeholder="placeholderText" :maxLength="20" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import * as api from '@/api/demandPool';
import { SUCCESS_CODE } from '@/config';
export default {
  name: 'AddDemandCategory',
  props: {
    // 是否编辑一级分类
    isEditFirstLevelCategory: {
      type: Boolean,
      default: true,
    },
    // 分类名称
    classifyName: {
      type: String,
      default: '',
    },
    // 分类id
    classifyId: {
      type: String,
      default: null,
    },
    // 是否显示
    showEditDemandCategory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 一二级分类文案
    firstLevelOrSecondLevelText() {
      return this.isEditFirstLevelCategory ? '一' : '二';
    },
    // 弹框title
    modalTitle() {
      return `编辑${this.firstLevelOrSecondLevelText}级分类`;
    },
    // 占位符文案
    placeholderText() {
      return `请输入${this.firstLevelOrSecondLevelText}级分类名称, 不超过20个字`;
    },
    // 分类label
    categoryLabel() {
      return `${this.firstLevelOrSecondLevelText}级分类名称`;
    },
  },
  watch: {
    classifyName: {
      handler(newVal) {
        this.form.classifyName = newVal;
      },
      immediate: true,
    },
    classifyId: {
      handler(newVal) {
        this.form.classifyId = newVal;
      },
      immediate: true,
    },
  },
  data() {
    return {
      confirmLoading: false, // 确定按钮loading
      form: {
        classifyName: '', // 需求分类名称
        classifyId: null, // 主键id
      },
      rules: {
        classifyName: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
      },
    };
  },
  methods: {
    // 提交
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.editDemandCategory();
        }
      });
    },
    // 取消
    cancel() {
      this.$emit('update:show-edit-demand-category', false);
    },
    /** 网络请求 */
    // 添加需求分类
    async editDemandCategory() {
      try {
        this.confirmLoading = true;
        const result = await api.editDemandCategoryForDemandPool(this.form);
        this.confirmLoading = false;
        if (result.code === SUCCESS_CODE) {
          this.$message.success(result.msg);
          this.$emit('update:show-edit-demand-category', false);
          this.$emit('refreshDemandCategoryList');
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        console.log(error);
        this.$message.error('修改需求分类失败');
        this.confirmLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
